import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import MainLayout from "../../components/MainLayout"
import { Helmet } from "react-helmet"
import {
  portfolio,
  portfolioNote,
  blogs,
  blog,
  blogThumbImage,
} from "../../styles/blogs.module.css"

export default function Blogs({ data }) {
  const blogsData = data.blogs.nodes

  return (
    <MainLayout>
      <Helmet>
        <title>Linguolab Blog</title>
        <meta
          name="description"
          content="Recopilación de todos nuestros tips, consejos y recursos para aprender idiomas"
        ></meta>
        <meta
          name="keywords"
          content="linguolab, blog, posts, información, aprender idiomas, aprender, idiomas, cursos, clases, plataforma, online, zoom, virtual, linguo, ingles, inglés, frances, francés, aleman, alemán"
        />
        <html lang="es" />
      </Helmet>
      <div className="page-header">
        <div className="page-header-titles">
          <h2>Blog</h2>
        </div>
        <GatsbyImage
          image={data.titleImage.childImageSharp.gatsbyImageData}
          className="page-header-background"
          alt="thumb"
          objectFit="cover"
          objectPosition="24% 50%"
        />
      </div>
      <div className="layout">
        <div className={`page-content ${portfolio}`}>
          <div className={`layout grid ${blogs}`}>
            {blogsData.map(blogData => (
              <Link
                to={`/blogs/${blogData.frontmatter.slug}`}
                key={blogData.id}
                className={blog}
              >
                <div>
                  <div className={blogThumbImage}>
                    {/* <h1>{blogData.frontmatter.language}</h1> */}
                    <GatsbyImage
                      image={
                        blogData.frontmatter.thumb.childImageSharp
                          .gatsbyImageData
                      }
                      alt={blogData.frontmatter.slug}
                    />
                  </div>
                  <h3>{blogData.frontmatter.shortTitle}</h3>
                </div>
              </Link>
            ))}
          </div>
          <p className={portfolioNote}>
            ¿Interesado en los cursos? <Link to="/contact">Contáctanos</Link>
          </p>
        </div>
      </div>
    </MainLayout>
  )
}

// export page query
export const query = graphql`
  query BlogsPage {
    blogs: allMarkdownRemark(
      filter: { frontmatter: {type: {eq: "blog"}} }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        frontmatter {
          slug
          title
          shortTitle
          language
          thumb {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
    contact: site {
      siteMetadata {
        contactEmail
      }
    }
    titleImage: file(relativePath: { eq: "featured/title-3.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`
